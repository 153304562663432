.flight-search-form {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.trip-options {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.trip-options label {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group input[type="number"] {
  width: 50px;
  margin-right: 10px;
}

.special-fare {
  margin-bottom: 20px;
}

.special-fare-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.special-fare-options label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.search-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #0056b3;
}
