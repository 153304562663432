/* src/TravelMenu.css */

.travel-menu {
  position: relative;
  bottom: 160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 2px 6px 5px rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  margin: 0 auto;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s ease;
  width: 80px;
}

.menu-item:hover {
  color: #007bff;
}

.menu-item span {
  margin-top: 5px;
}

.menu-item svg {
  font-size: 24px;
}
.carousel .control-dots {
  position: absolute;
  bottom: 120px;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 720px) {
  .travel-menu {
    bottom: 0px;
    margin-top: 16px;
    width: 410px;
    padding: 0px;
    margin-bottom: 10px;
  }
  .carousel .control-dots {
    position: absolute;
    bottom: 30px;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
  }
}
