

  
  .cont {
    max-width: 250px;
    // margin: 2rem auto;
  }
  
  .product-card {
    background-color: var(--card-background);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .product-card__image {
    height: 180px;
    overflow: hidden;
  }
  
  .product-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover .product-card__image img {
    transform: scale(1.05);
  }
  
  .product-card__info {
    padding: 10px;
  }
  
  .product-card__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom:5px;
    color: var(--text-color);
    text-align: left;
  }
  
  .product-card__description {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .product-card__price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .product-card__price {
    font-size: 16px;
    font-weight: 600;
    color: red;
  }
  
  .product-card__btn {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .product-card__btn:hover {
    background-color: #c71729;
  }
  
  @media (max-width: 480px) {
    .cont {
      max-width: 100%;
      padding: 0 20px;
    }
  
    .product-card__image {
      height: 200px;
    }
  
    .product-card__title {
      font-size: 20px;
    }
  
    .product-card__description {
      font-size: 13px;
    }
  
    .product-card__price {
      font-size: 18px;
    }
  
    .product-card__btn {
      padding: 8px 16px;
      font-size: 13px;
    }
  }
  