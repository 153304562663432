.download-wrap {
  height: 375px;
}

.download-wrapper {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin: 0px auto;
}

.download-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#downloads-info {
  margin-bottom: 35px;
  text-align: center;
  margin-top: 25px;
}

.download-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-links img {
  margin: 0 3px;
}

.download-section h2 {
  margin-bottom: 12px;
}

.download-section p {
  color: #7b7b7b;
  font-size: 14.4px;
  line-height: 21.168px;
  margin: 35px 45px;
}

.store {
  cursor: pointer;
}

/* Media Queries */

@media only screen and (min-width: 1600px) {
  .download-wrapper {
    width: 50%;
  }
}
