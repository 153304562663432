@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

h2 {
  font-size: clamp(1rem, 0.875rem + 0.625vw, 1.5rem);
  line-height: 1.3;
  font-weight: 700;
  color: #fff;
}

section {
  display: grid;
  grid-template-columns: 25% 30% 15% 25%;
  gap: 15px;
  place-content: center;
  grid-template-rows: 50% 50%;
  height: 80vh;
  min-height: 460px;
  padding: max(2vh, 1.5rem);
}

@media screen and (max-width: 690px) {
  section {
    height: 65vh;
  }
}

@media screen and (max-width: 470px) {
  section {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 35%);
  }
}

.card {
  border-radius: 25px;
  box-shadow: -2px 4px 15px rgb(0 0 0 / 26%);
}

.card:nth-child(2) {
  grid-column: 2/3;
  grid-row: span 2;
}

.card:nth-child(3) {
  grid-column: span 2;
}

@media screen and (max-width: 690px) {
  .card:nth-child(2) {
    grid-column: span 1;
    grid-row: span 1;
  }
  .card:nth-child(3) {
    grid-column: 2/4;
    grid-row: 1/2;
  }
  .card:nth-child(6) {
    grid-column: 2/4;
    grid-row: 2/3;
  }
}

@media screen and (max-width: 470px) {
  .card:nth-child(5) {
    grid-column: span 2;
  }
}

.card p {
  font-size: clamp(0.9rem, 0.875rem + 0.125vw, 1rem);
  line-height: 1.4;
  margin-bottom: 10px;
}

.card img {
  border-radius: 25px;
}

.card .card-img {
  position: relative;
  height: 100%;
}

.card .card-img .card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  color: #fff;
  background: rgb(2, 2, 46);
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 57%) 0%,
    rgb(255 255 255 / 0%) 100%
  );
  width: 100%;
  height: 100%;
  border-radius: 25px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.card .card-img span {
  position: absolute;
  top: 25px;
  left: min(2vmax, 1.563rem);
  color: #ff7b29;
  background: #fff;
  border-radius: 50px;
  padding: 2px 8px 2px 6px;
  display: flex;
  box-shadow: 0px 1px 20px #0000002b;
}

@media screen and (max-width: 690px) {
  .card .card-img span {
    top: 20px;
  }
}

@media screen and (max-width: 470px) {
  .card .card-img span {
    top: 15px;
  }
}

.card .card-img span svg {
  fill: #ff7b29;
  width: 20px;
  margin-right: 2px;
}
