.collections-wrapper {
  width: 85%;
  margin: 0px auto;
}

.collections-wrapper h1 {
  text-align: left;
  color: #545454;
  font-weight: 300;
  font-size: 27px;
}

.cards-wrapper {
  display: flex;
  width: 1152px;
  height: auto;
  margin: 35px auto;
  flex-wrap: wrap;
}

/* Collection Card */

.collection-card-wrapper {
  width: 288px;
  height: 288px;
  text-align: center;
}
/* Image zoom on hover + Overlay colour */
.card-parent {
  width: 288px;
  height: 288px;
  overflow: hidden;
  position: relative;
  /* float: left; */
  display: inline-block;
  cursor: pointer;
}

.card-child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: inset 0px -36px 54px -10px rgba(0, 0, 0, 0.5);
}

.card-child p {
  font-size: 17.5px;
  color: #ffffff !important;
  text-align: center;
  margin: auto;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 245px;
  font-weight: 500;
}

.card-parent:hover .card-child,
.card-parent:focus .card-child {
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.card-parent:hover .card-child:before,
.card-parent:focus .card-child:before {
  display: block;
  background-color: rgba(52, 73, 94, 0.5);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.card-parent:hover p,
.card-parent:focus p {
  display: block;
}

.card-child:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Media Queries */

@media only screen and (min-width: 1200px) and (max-width: 1380px) {
  .collections-wrapper {
    width: 95%;
  }

  .cards-wrapper {
    margin-left: 90px;
  }
}

@media only screen and (min-width: 1600px) {
  .collections-wrapper {
    width: 70%;
  }
}
