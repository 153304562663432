* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Heart */

// .heart-parent {
//   margin-top: 30px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

// .heart {
//   margin: auto;
//   display: inline-block;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: #ed3d4f;
//   height: 20px;
//   width: 20px;
//   transform: rotate(-45deg);
//   animation-name: beat;
//   animation-duration: 1300ms;
//   animation-iteration-count: infinite;
// }
// .heart:before,
// .heart:after {
//   content: "";
//   background-color: #ed3d4f;
//   border-radius: 50%;
//   height: 20px;
//   position: absolute;
//   width: 20px;
// }

// .heart:before {
//   top: -10px;
//   left: 0;
// }

// .heart:after {
//   left: 10px;
//   top: 0;
// }

// @keyframes beat {
//   0% {
//     transform: scale(1) rotate(-45deg);
//   }
//   50% {
//     transform: scale(0.9) rotate(-45deg);
//   }
// }
