.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.link {
  color: #fff;
  outline: none;
}

.link:hover {
  color: #fff;
}

#external {
  color: #d03034;
  padding-top: 15px;
}

.mobile-tablet {
  color: #545454;
  margin-top: 30%;
  margin-left: 33%;
  width: 50%;
}

.mobile-tablet p {
  margin-top: 10px;
}

.mobile-tablet img {
  width: 200px;
}

@media only screen and (max-width: 500px) {
  .mobile-tablet {
    width: 60%;
    margin: 120px auto;
  }
}
