.city-tour-wrapper {
    position: relative;
    display: inline-block; /* Ensure the wrapper adjusts to the size of the image */
  }
  
  .city-tour-wrapper img {
    width: 100%; /* Make the image responsive */
    display: block; /* Remove any extra space below the image */
  }
  
  .tour-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Make sure the text is visible against the image */
    font-size: 2rem; /* Adjust the font size as needed */
    font-weight: bold; /* Make the title stand out */
    text-align: center; /* Center the text */
    padding: 10px;
    // background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background to the text */
    border-radius: 8px; /* Optional: Add rounded corners to the background */
  }

  .tour-carousel-wrapper {
    display: flex;
    gap: 30px;
  }
  .tour-city-card{
    // border: 1px solid black;
    // display: flex;
    width:20%;
    // height: 100px;
  }

  

  .tour-carousel-wrapper {
    display: flex;
    overflow-x: auto;
    // scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* Enables momentum scrolling on iOS */
    padding: 0px 50px;
  }
  
//   .tour-city-card {
//     flex: 0 0 auto;
//     scroll-snap-align: start;
//     margin-right: 16px; /* Space between cards */
//   }
  
  .image-wrapper {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    object-fit: cover; /* Ensures the image covers the card properly */
    border-radius: 50%; /* Optional: Add some border-radius to the images */
  }
  

  
  .tour-content{
   text-align: center;
   text-decoration: none;
   font-size: 14px;
  }

  .tour-cities-wrapper{
  
    width: 80%;
    margin: 0 auto;
  }
  
  .tours-title{

    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    padding: 15px;
  }
  .about-tour-wrapper{
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: justify;
    margin: 20px auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 20px;
    font-size: 12px;

  }
  .read-more-btn{
    width: 72px;
    background: none;
    outline: none;
    font-size: 12px;
    background-color: none;
    border: none;
    color: #f36911;
    cursor: pointer;

  }
  .text-title{
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;

  }
  .tourActivity{
   
    display: flex;
    flex-wrap: wrap;
    gap : 30px
  }
.outer-wrapper{
  // border: 1px solid black;
  padding: 0px 150px;
}
  @media (max-width: 480px) {
    .tour-carousel-wrapper {
     
     padding: 0px;
    }
    .tour-city-card{
      // border: 1px solid black;
      // display: flex;
      width:100%;
      // height: 100px;
    }
    .tour-title{
      font-size: 1.5rem;
    }
    .city-tour-wrapper {
      
      height: 150px;
  }
  .about-tour-wrapper {
    width: 90%;
}
.outer-wrapper{
  // border: 1px solid black;
  padding: 0px;
}
  }