.header-wrap {
  position: relative;
}

.navbar-fixed-top {
  position: fixed;

  right: 0;

  left: 0;

  z-index: 999;
}

.header-wrapper {
  width: 100%;
  margin: 0px auto 0;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  background: #fff;
  height: 120px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 9px 0px;
  padding-bottom: 10px;
}

.header-left {
  display: flex;
}

.first-line {
  display: flex;
  cursor: pointer;
  align-items: end;
}
.mobile-logo {
  display: none;
}

.first-line p {
  font-size: 13px;
  color: rgb(84, 84, 84);
}

#logo {
  /* height: 25px; */
  width: 150px;
  /* padding-top: 6px; */
}

.select-city {
  width: 171px;
  height: 40px;
  background-color: #f4f4f4;
  margin-left: 35px;
  border-radius: 10px;
}

.select-experience {
  background-color: #f4f4f4;
  margin-left: 5px;
  display: flex;
  border-radius: 10px;
}

.select-experience input {
  font-family: "Poppins";
  border: none;
  background: #f4f4f4;
  width: 580px;
  height: 40px;
  font-size: 16px;
  outline: none;
  padding-left: 25px;
  border-radius: 10px;
}

.select-experience i {
  color: #cbcbcb;
  font-size: 18px;
  padding-top: 12px;
  padding-right: 20px;
}

.second-line {
  display: flex;
  cursor: pointer;
  padding-top: 20px;
  justify-content: space-between;
}

.second-line p {
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  margin-right: 30px;
}

.second-line p:hover {
  color: #ec1943;
}

.best-picked {
  display: flex;
}

.support {
  display: flex;
  margin-left: 120px;
  justify-content: flex-end;
}

.arrow-down {
  padding-left: 5px;
}

.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 25px;
}

.sign-in {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.sign-in-user {
  color: #e8e8e8;
  font-size: 20px;
}

.lastnamearya-img img {
  width: 20px;
  border-radius: 50%;
}

.header-right p {
  color: #545454;
  font-size: 13px;
}

.download-app {
  display: flex;
  align-items: center;
  margin-top: -3px;
  min-width: 120px;
  justify-content: flex-end;
  cursor: pointer;
}

.download-app p {
  margin-left: 5px;
}

#mobile-app {
  max-width: 22px;
  display: inline-block;
  vertical-align: middle;
  -webkit-filter: invert(200%);
  filter: invert(200%);
  height: 22px;
}

/* Slideshow */

#slideshow {
  position: relative;
  height: 450px;
}

#slideshow > .img-crop {
  position: absolute;
}

.test {
  font-family: Avenir, proxima-nova, arial, sans-serif;
  color: #545454;
}

/* Search Bar */

.search-bar-div {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -28px;
}

.search-bar {
  position: relative;
  display: flex;
  justify-content: center;
}

.select-city-large {
  width: 216px;
  height: 54px;
  border-radius: 10px;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.2);
  background: white;
  cursor: pointer;
  display: flex;
}

.select-city-large i {
  color: #9a9a9a;
  padding-top: 22px;
  padding-left: 30px;
  font-size: 15px;
}

.select-experience-large {
  border-radius: 10px;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.15);
  margin-left: 5px;
  background: white;
}

.select-experience-large input {
  font-family: "Poppins";
  border: none;
  font-size: 16px;
  padding-left: 20px;
  border-radius: 10px;
  width: 280px;
  height: 52px;
  outline: none;
  -webkit-transition: width 0.3s ease-out;
  -moz-transition: width 0.3s ease-out;
  -o-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out;
}

.select-experience-large input:focus-within {
  width: 450px;
}

.select-experience-large i {
  padding-right: 20px;
  color: #cbcbcb;
}

#go {
  font-size: 16.2px;
  line-height: 22.68px;
  font-weight: 500;
  width: 110px;
  height: 54px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  background: linear-gradient(
    -45deg,
    #eb294b,
    #e73c7e,
    #dd2953,
    #cd853f,
    #ce1232,
    #eb294b,
    #e73c7e
  );
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 10s ease infinite;
  margin-left: 5px;
  cursor: pointer;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media only screen and (max-width: 720px) {
  .second-line {
    display: none;
  }
  .first-line {
    display: none;
  }
  .search-bar-div {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .select-experience-large input:focus-within {
    width: 320px;
  }
  .mobile-logo {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    align-items: center;
  }
  .hamburger-icon {
    width: 30px;
    height: 30px;
  }
  .sh-logo {
    width: 140px;
  }
  .header-wrapper {
    display: none;
  }
}
