.headout-picks-wrapper {
  /* width: 85%; */
  margin: 35px auto;
  /* margin: 25px 16px; */
  /* height: 420px; */
}

.headout-picks-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
}

.headout-picks-nav h1 {
  color: #545454;
  font-weight: 500;
  font-size: 26px;
  margin: 0px;
}

.view-all {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: #545454;
}

.view-all h3 {
  margin: 0px;
  font-size: 16.2px;
  line-height: 22.68px;
  font-weight: 400;
}

.view-all i {
  font-size: 18px;
  padding-left: 6px;
  padding-right: 6px;
}

.view-all:hover {
  color: #4fc3f7;
}

.view-all:hover i {
  padding-left: 8px;
  padding-right: 4px;
}

.top-picked-carousel-wrap {
  /* width: 1150px; */
  overflow-x: scroll; /* Enable horizontal scrolling */
  gap: 10px;
  /* padding: 10px; */
  /* scroll-snap-type: x mandatory; */
  margin: 0 auto;
}

.top-picked-carousel-wrap::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
/* Picked Card */

.exp-card {
  /* width: 270px; */
  height: 345px;
  position: relative;
  box-shadow: -1px 4px 20px -3px rgba(80, 79, 79, 0.3);
  margin: 10px 10px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  border-radius: 10px;
}

.cashback {
  padding: 0px 16px;
  background: #4fc3f7;
  position: absolute;
  z-index: 1;
  top: 15px;
  left: -10px;
  color: #fff;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
}

.exp-card .cashback:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0;
  border-top: 1em solid #545454;
  position: absolute;
  left: 0;
  bottom: -1em;
}

.exp-heart i {
  color: white;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 15px;
  font-weight: 400;
  transition: transform 0.3s ease-in-out;
}

.exp-heart i:hover {
  font-weight: 700;
  transform: scale(1.15);
  color: #f43361;
}

.cashback p {
  font-size: 9px;
  padding: 5px 0px;
  margin: 0;
  font-weight: 700;
}

.exp-card:hover {
  transform: translateY(-0.2em);
  box-shadow: -4px 8px 15px -3px rgba(80, 79, 79, 0.4);
}

.exp-card-img {
  background-size: cover;
  background-repeat: no-repeat;
  width: 300px;
  height: 190px;
  border-radius: 10px 10px 0px 0px;
}

.exp-info-wrap {
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  margin: 0px;
}

#exp-city {
  text-align: left;
  color: #9a9a9a;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
  padding-top: 8px;
  padding-bottom: 5px;
}

#exp-about {
  text-align: left;
  color: #b9b9b9;
  font-size: 11px;
  line-height: 15px;
  padding-top: 8px;
  margin-bottom: 3px;
  margin-top: 0px;
}

#exp-description {
  color: #545454;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
  margin: 0px;
  padding-right: 5px;
}

.exp-content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 131px;
}

.price-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 10px;
  height: auto;
}

.discount {
  color: #49d1fd;
  font-size: 11px;
  text-align: left;
  padding-bottom: 12px;
}

.discount p {
  margin: 0px;
  padding-top: 10px;
  color: #49d1fd;
}

.discount i {
  padding: 0 3px;
}

.ratings-section {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.stars {
  width: 55px;
  /* height: 18px; */
  border-radius: 10px;
  background: linear-gradient(340deg, #ffbb58, #f5c684);
  padding: 1px;
}

#stars-p {
  color: white;
  font-weight: 700;
}

#star {
  margin-left: 5px;
}

#ratings {
  padding-left: 6px;
  font-size: 12px;
}

.ratings-section p {
  color: #9a9a9a;
  font-size: 12px;
  margin: 0px;
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.price p {
  color: #9a9a9a;
  font-size: 11px;
  margin: 0px;
}

#last-price {
  font-size: 13px;
  padding: 1px 0px;
  text-decoration: line-through;
}

#price {
  color: #545454;
  font-size: 19.8px;
  padding-bottom: 10px;
  font-weight: 500;
}

#like {
  position: absolute;
  top: 10;
  left: 10;
}

/* View More */

.show-more {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #fd8383, #ff2e73);
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-more p {
  margin: 0px;
  background: -webkit-linear-gradient(45deg, #8f6b29, #fde08d, #df9f28);
  background: linear-gradient(45deg, #8f6b29, #fde08d, #df9f28);
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  font-weight: 500;
  font-size: 13.5px;
  width: 140px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

/* Media Queries */

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .headout-picks-wrapper {
    width: 95%;
  }
}

@media only screen and (min-width: 1600px) {
  .headout-picks-wrapper {
    width: 70%;
  }
}

.picked-slider-container {
  display: flex;
}

@media only screen and (max-width: 720px) {
  .headout-picks-wrapper {
    margin: 25px 16px;
  }
  .exp-card {
    width: 270px;
    height: 320px;
  }
  .exp-card-img {
    width: 270px;
    height: 166px;
  }
  .headout-picks-nav h1 {
    font-size: 22px;
  }
}
